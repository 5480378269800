<template>
</template>
<script>
import global from '../assets/js/global'
export default {
    created(){
        this.isToken()
    },
    methods:{
        // 判断是否有token
        isToken(){
            const ctoken = this.$route.query.token;
            if(this.$global.getCookie('ctoken')){
                this.getinfo()
            }else if(ctoken){
                this.$global.setCookie('ctoken',ctoken)
                this.getinfo()
                // this.ifChannel()
            }else{
                window.location.href = global.loginURL
            }
        },
        // 获取用户信息
        async getinfo(){
            const {data:res} = await this.$http.get('c-admin/account_channel_info/')
            if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.$global.setCookie("loginData",JSON.stringify(res.data));
            this.$router.push('/Statistics');
        },
        // 判断是否激活
        // async ifChannel(){
        //     const {data:res} = await this.$http.get('/c-admin/channel_is_active/')
        //     console.log(res)
        //     if(res.data){
        //         this.$router.push('/Statistics')
        //     }else{
        //         this.$router.push('/PerfectChannel')
        //     }
        // },
    }
}
</script>